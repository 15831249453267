import Axios from "axios";
import moment from "moment";
import dayjs from "dayjs";

import colorLib from "@kurkle/color";
import queryString from "query-string";
import { auth } from "utils/auth";

const consts = {
  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
  TOKEN_KEY: "_t4gd-*-",
  REFRESH_KEY: "_se4you^",
  TOKEN_SAVEID: "_savedid_",
  EVENT_TIME: "_eventtime_",
  HANGUL_CHO: [
    "ㄱ",
    "ㄲ",
    "ㄴ",
    "ㄷ",
    "ㄸ",
    "ㄹ",
    "ㅁ",
    "ㅂ",
    "ㅃ",
    "ㅅ",
    "ㅆ",
    "ㅇ",
    "ㅈ",
    "ㅉ",
    "ㅊ",
    "ㅋ",
    "ㅌ",
    "ㅍ",
    "ㅎ",
  ],

  TERM_BUTTONS: [
    {
      id: "yesterday",
      label: "전일",
      date: () => {
        return { start: dayjs().subtract(1, "days"), end: dayjs() };
      },
    },
    {
      id: "before7days",
      label: "7일",
      date: () => {
        return { start: dayjs().subtract(7, "days"), end: dayjs() };
      },
    },
    {
      id: "month1",
      label: "1개월",
      date: () => {
        return { start: dayjs().subtract(1, "months"), end: dayjs() };
      },
    },
    {
      id: "month3",
      label: "3개월",
      date: () => {
        return { start: dayjs().subtract(3, "months"), end: dayjs() };
      },
    },
    {
      id: "year1",
      label: "1년",
      date: () => {
        return { start: dayjs().subtract(1, "years"), end: dayjs() };
      },
    },
  ],

  INQUIRY_BUTTONS: [
    {
      id: "today",
      label: "오늘",
      date: () => {
        return { start: moment(), end: moment() };
      },
    },
    {
      id: "yesterday",
      label: "전일",
      date: () => {
        return { start: moment().subtract(1, "days"), end: moment() };
      },
    },
    {
      id: "before7days",
      label: "7일",
      date: () => {
        return { start: moment().subtract(7, "days"), end: moment() };
      },
    },
    {
      id: "month1",
      label: "1개월",
      date: () => {
        return { start: moment().subtract(1, "months"), end: moment() };
      },
    },
    {
      id: "month3",
      label: "3개월",
      date: () => {
        return { start: moment().subtract(3, "months"), end: moment() };
      },
    },
    {
      id: "year1",
      label: "1년",
      date: () => {
        return { start: moment().subtract(1, "years"), end: moment() };
      },
    },
  ],
  INQUIRY_ORDER: [
    { value: "desc", label: "최근일로부터" },
    { value: "asc", label: "과거일로부터" },
  ],

  POWER_PROVIDER: [
    { value: 0, label: "KPS" },
    { value: 1, label: "한전" },
  ],

  REC_CONTRACT_TYPE: [
    { value: 0, label: "혼합" },
    { value: 1, label: "REC고정" },
    { value: 2, label: "REC변동" },
  ],

  ROLE: [
    { value: "0", op: "Owner", label: "사업주" },
    { value: "1", op: "Safety", label: "안전관리자" },
    { value: "2", op: "Group", label: "그룹관리자" },
    { value: "3", op: "Employee", label: "운영관리자" },
    { value: "4", op: "Admin", label: "시스템관리자" },
  ],

  CLOUD_API: "https://cloud.solarwiz.co.kr/v1/cloud",

  FILE_TYPES: [
    { contentType: "application/pdf", type: "PDF" },
    { contentType: "image/png", type: "PNG" },
    { contentType: "image/jpeg", type: "JPG" },
  ],

  BASEDEV: [
    { value: "IVT", label: "인버터" },
    { value: "VCB", label: "VCB" },
    { value: "ACB", label: "ACB" },
  ],

  USER_TYPES: [
    { value: "Owner", label: "사업주" },
    { value: "Safety", label: "안전관리자" },
    { value: "Group", label: "발전소 관제" },
    { value: "Employee", label: "운영 관리자" },
    { value: "Admin", label: "시스템 관리자" },
  ],

  USER_ACTIVE: [
    { value: "true", label: "활성" },
    { value: "false", label: "비활성" },
  ],

  DEVICE_TYPES: [
    { value: "IVT", label: "IVT" },
    { value: "VCB", label: "VCB" },
    { value: "ACB", label: "ACB" },
    { value: "WEATHER", label: "WEATHER" },
    { value: "JB", label: "접속반" },
  ],

  DEVICE_COMMTYPES: [
    { value: "MODBUS", label: "MODBUS" },
    { value: "MODBUS-TCP", label: "MODBUS-TCP" },
    { value: "MODBUS-AS", label: "MODBUS-AS" },
  ],

  CONTROL_PORTS: [{ value: "GP", label: "GP" }],
};

// cloud axios
// const cloudAxios = Axios.create({ baseURL: `${process.env.REACT_APP_CLOUD}` });

// let isAlreadyFetchingAccessToken = false;
// let subscribers = [];

// const onAccessTokenFetched = (accessToken) => {
//   subscribers = subscribers.filter((callback) => callback(accessToken));
// };
// const addSubscriber = (callback) => {
//   subscribers.push(callback);
// };

// const axios = Axios.create({
//   baseURL: `${process.env.REACT_APP_API_BASEURL}`,
// });
// axios.interceptors.request.use(
//   async (config) => {
//     let token = await auth.getToken(); //localStorage.getItem(consts.TOKEN_KEY);
//     config.headers = {
//       ...config.headers,
//       ...(!config.forGuest
//         ? {
//             Authorization: `${!!token ? token : ""}`,
//           }
//         : {}),
//     };
//     config.withCredentials = true;
//     return config;
//   },

//   function (error) {
//     console.log({ error });
//     return Promise.reject(error);
//   }
// );
// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const {
//       config,
//       response: { status },
//     } = error;
//     const originalRequest = config;

//     if (status === 401) {
//       if (!isAlreadyFetchingAccessToken) {
//         isAlreadyFetchingAccessToken = true;
//         axios
//           .post("/accounts/refresh-token")
//           .then((response) => {
//             const newAccessToken = response.data.jwtToken;

//             let rememberMe = false;

//             if (!!localStorage.getItem(consts.TOKEN_KEY)) rememberMe = true;
//             auth.setToken({ token: newAccessToken, rememberMe });
//             //localStorage.setItem(consts.TOKEN_KEY, newAccessToken);
//             //SettingsBackupRestoreRounded()
//             isAlreadyFetchingAccessToken = false;
//             onAccessTokenFetched(newAccessToken);
//           })
//           .catch((error) => {
//             const { status } = error.response;
//             if (status === 400) {
//               //axios.post("/accounts/signout");

//               //localStorage.removeItem(consts.TOKEN_KEY);
//               //sessionStorage.removeItem
//               auth.logout();

//               window.location = "/login";
//             }
//           });
//       }

//       return new Promise((resolve) => {
//         addSubscriber((accessToken) => {
//           originalRequest.headers.Authorization = accessToken;
//           resolve(axios(originalRequest));
//         });
//       });
//     }

//     return Promise.reject(error);
//   }
// );

const cloudAxios = Axios.create({ baseURL: `${process.env.REACT_APP_CLOUD}` });

// axios refresh logic
let isTokenRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((failed) => {
    if (error) {
      failed.reject(error);
    } else {
      failed.resolve(token);
    }
  });
  failedQueue = [];
};

const REFRESH_URL = "/accounts/refresh-token";

const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_API_BASEURL}`,
});

axios.interceptors.request.use(
  async (config) => {
    const accessToken = await auth.getToken();

    //console.log({ config });

    //const newConfig = {
    //      ...config,
    // };
    //console.log({ newConfig });
    // config = {
    //   ...config,
    //   headers: {
    //     ...config.headers,
    //     ...(!config.forGuest
    //       ? { Authorization: accessToken ? accessToken : "" }
    //       : {}),
    //   },
    // };

    // config = {
    //   ...config,
    //   headers: {
    //     ...config.headers,
    //     ...(!config.forGuest ? { Authorization: !token ? token : "" } : {}),
    //   },
    //   withCredentials: true,
    // };

    config.headers = {
      ...config.headers,
      ...(!config.forGuest
        ? {
            ...(accessToken ? { Authorization: accessToken } : {}),
          }
        : {}),
    };
    config.withCredentials = true;

    // config = {
    //   ...config,
    //   headers: {
    //     ...config.headers,
    //     ...(!config.forGuest ? { authentication: token ? token : "" } : {}),
    //   },
    // };

    //console.log({ config });

    return config;
  },
  (error) => {
    return Promise.resolve(error);
  }
);

// console.log({ failedQueue });

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error;

    const originalRequest = config;

    // 토큰 만료시
    if (status === 401) {
      if (isTokenRefreshing && !originalRequest._retry) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            console.log({ originalRequest, token });
            originalRequest.headers.Authorization = token;
            originalRequest._retry = true;
            return axios(originalRequest);
          })
          .catch((err) => {
            // expected the promise rejection reason to be an error
            // Promise.reject으로 전달하는 객체가 Error가 아닌 경우 발생하는 경고 해결을 위한 로직
            if (err instanceof Error) {
              return Promise.reject(err);
            }
            return Promise.reject(
              new Error(err?.message || "Unknown error occurred")
            );
          });
      }

      originalRequest._retry = true;
      isTokenRefreshing = true;

      const refreshToken = await auth.getRefreshToken();
      console.log({ refreshToken });

      return new Promise(function (resolve, reject) {
        axios
          .post(REFRESH_URL)
          .then(async (response) => {
            const newAccessToken = response.data.jwtToken;
            console.log({ newAccessToken });

            let rememberMe = false;
            if (localStorage.getItem(consts.TOKEN_KEY)) rememberMe = true;
            await auth.setToken({ token: newAccessToken, rememberMe });
            originalRequest.headers.Authorization = newAccessToken;

            processQueue(null, newAccessToken);
            resolve(axios(originalRequest));
          })
          .catch(async (error) => {
            await auth.logout();
            processQueue(error, null);

            //reject(error);
            reject(
              error instanceof Error
                ? error
                : new Error(error?.message || "Unknown error occurred")
            );
          })
          .finally(() => {
            isTokenRefreshing = false;
          });
      });
    }

    if (error instanceof Error) {
      return Promise.reject(error);
    }
    return Promise.reject(
      new Error(error?.message || "Unknown error occurred")
    );
  }
);

/*
axios get 요청시 params를 배열 구조로 전송하고자 할 경우
직렬화 해주는 함수수
*/
const paramsSerializer = (params) => {
  if (Array.isArray(params)) {
    // 각 키에 대한 배열 구성
    const columns = Object.keys(params[0]);
    const serializedParams = columns.reduce((acc, key) => {
      acc[key] = params.map((item) => item[key]);
      return acc;
    }, {});
    return queryString.stringify(serializedParams);
  }
  return queryString.stringify(params);
};

const regex = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  CELLPHONE: /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/,
  CELLPHONE_NUMBERONLY: /^01[016789]{1}\d{7,8}$/,
  PASSWORD: /^.*(?=^.{8,20}$)(?=.*[a-z|A-Z])(?=.*\d)(?=.*[.!@#$%]).*$/,
  CURRENCY: /\B(?=(\d{3})+(?!\d))/g,
  DATE: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
  CNV_PHONENUMBER: /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
};

const isEmpty = (value) => {
  return !!(
    value === "" ||
    value === null ||
    value === undefined ||
    (value !== null && typeof value === "object" && !Object.keys(value).length)
  );
};
const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// sorting 관련
const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const setterPartition = async (array, n) => {
  let arr = array.map((item) => item);
  let len = arr.length;
  let cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
  let tmp = [];

  for (let i = 0; i < cnt; i++) {
    tmp.push(arr.splice(0, n));
  }
  return tmp;
};

const durationMinute = (val) => {
  return moment.duration(moment().diff(moment(val))).asMinutes();
};

const numberFixedFormat = (value, n) => {
  return value || value === 0
    ? String(parseFloat(Number(value).toFixed(n))).replace(regex.CURRENCY, ",")
    : null;
};

const COLORS = [
  "#4dc9f6",
  "#f67019",
  "#f53794",
  "#537bc4",
  "#acc236",
  "#166a8f",
  "#00a950",
  "#58595b",
  "#8549ba",
  "#1941A5", //Dark Blue
  "#AFD8F8",
  "#F6BD0F",
  "#8BBA00",
  "#A66EDD",
  "#F984A1",
  "#CCCC00", //Chrome Yellow+Green
  "#999999", //Grey
  "#0099CC", //Blue Shade
  "#FF0000", //Bright Red
  "#006F00", //Dark Green
  "#0099FF", //Blue (Light)
  "#FF66CC", //Dark Pink
  "#669966", //Dirty green
  "#7C7CB4", //Violet shade of blue
  "#FF9933", //Orange
  "#9900FF", //Violet
  "#99FFCC", //Blue+Green Light
  "#CCCCFF", //Light violet
  "#669900", //Shade of green
];

const getColor = (index) => {
  return COLORS[index % COLORS.length];
};

const transparentize = (value, opacity) => {
  //var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value)
    .alpha(opacity === undefined ? 0.5 : 1 - opacity)
    .rgbString();
};

function getSundayFromDate(d) {
  // 입력값(d) : 2022-11-24
  // getDate() : 24
  // getDay()  0 : 일요일, 1~6 : 월~토
  let paramDate = new Date(d);
  paramDate.setDate(paramDate.getDate() - paramDate.getDay());
  return paramDate;
}

function getSaturdayFromDate(d) {
  let paramDate = new Date(d);
  paramDate.setDate(paramDate.getDate() + (6 - paramDate.getDay()));
  return paramDate;
}

function getFirstdayFromDate(d) {
  let paramDate = new Date(d);
  return new Date(paramDate.getFullYear(), paramDate.getMonth(), 1);
}

function getLastdayFromDate(d) {
  let paramDate = new Date(d);
  return new Date(paramDate.getFullYear(), paramDate.getMonth() + 1, 0);
}

function getExtensionOfFilename(filename) {
  let _fileLen = filename.length;

  /*
   * lastIndexOf('.')
   * 뒤에서부터 .의 위치를 찾기 위한 함수
   * 검색 문자열의 위치를 반환한다.
   * 파일 이름에 .이 포함된 경우가 있기 때문에 lastIndexOf() 사용
   */

  let _lastDot = filename.lastIndexOf(".");

  // 확장자 명만 추출한 후 소문자로 변경
  let _fileExt = filename.substring(_lastDot, _fileLen).toLowerCase();
  return _fileExt;
}

const extractFileExt = (file) => {
  let lastDot = file.lastIndexOf(".");

  let name = file.substring(0, lastDot);
  let ext = file.substring(lastDot, file.length);

  return { name, ext };
};

const shortenWords = (str, length = 25, tail = "(...)") => {
  let result = str;
  if (str.length > length) {
    result = str.substr(0, length) + tail;
  }
  return result;
};

const getFirstLetter = (value) => {
  const firstLetter = value[0].toUpperCase();
  const uniCode = firstLetter.charCodeAt() - 44032;
  if (/\d/.test(firstLetter)) {
    return "0-9";
  } else if (uniCode > -1 && uniCode < 11172) {
    return consts.HANGUL_CHO[Math.floor(uniCode / 588)];
  }
  return firstLetter;
};

const fileDownloadFromCloud = async (uri, fileName, contentType) => {
  try {
    return cloudAxios.get(`cloud/${uri}`, {
      params: {
        fileName: fileName,
        contentType: contentType,
      },
      responseType: "blob",
      timeout: 30000,
    });
  } catch (e) {}
};

const getResourceUrlfromCloud = (url, fileName, contentType) => {
  return `${process.env.REACT_APP_CLOUD}/cloud/${url}?fileName=${fileName}&contentType=${contentType}`;
};

// LCC DFS 좌표변환을 위한 기초 자료
const RE = 6371.00877; // 지구 반경(km)
const GRID = 5.0; // 격자 간격(km)
const SLAT1 = 30.0; // 투영 위도1(degree)
const SLAT2 = 60.0; // 투영 위도2(degree)
const OLON = 126.0; // 기준점 경도(degree)
const OLAT = 38.0; // 기준점 위도(degree)
const XO = 43; // 기준점 X좌표(GRID)
const YO = 136; // 기1준점 Y좌표(GRID)

function dfs_xy_conv(code, v1, v2) {
  const DEGRAD = Math.PI / 180.0;
  const RADDEG = 180.0 / Math.PI;

  let re = RE / GRID;
  let slat1 = SLAT1 * DEGRAD;
  let slat2 = SLAT2 * DEGRAD;
  let olon = OLON * DEGRAD;
  let olat = OLAT * DEGRAD;

  let sn =
    Math.tan(Math.PI * 0.25 + slat2 * 0.5) /
    Math.tan(Math.PI * 0.25 + slat1 * 0.5);
  sn = Math.log(Math.cos(slat1) / Math.cos(slat2)) / Math.log(sn);
  let sf = Math.tan(Math.PI * 0.25 + slat1 * 0.5);
  sf = (Math.pow(sf, sn) * Math.cos(slat1)) / sn;
  let ro = Math.tan(Math.PI * 0.25 + olat * 0.5);
  ro = (re * sf) / Math.pow(ro, sn);
  let rs = {};
  if (code === "toXY") {
    rs["lat"] = v1;
    rs["lng"] = v2;
    let ra = Math.tan(Math.PI * 0.25 + v1 * DEGRAD * 0.5);
    ra = (re * sf) / Math.pow(ra, sn);
    let theta = v2 * DEGRAD - olon;
    if (theta > Math.PI) theta -= 2.0 * Math.PI;
    if (theta < -Math.PI) theta += 2.0 * Math.PI;
    theta *= sn;
    rs["x"] = Math.floor(ra * Math.sin(theta) + XO + 0.5);
    rs["y"] = Math.floor(ro - ra * Math.cos(theta) + YO + 0.5);
  } else {
    rs["x"] = v1;
    rs["y"] = v2;
    let xn = v1 - XO;
    let yn = ro - v2 + YO;
    let ra = Math.sqrt(xn * xn + yn * yn);
    if (sn < 0.0) ra = -ra;
    let alat = Math.pow((re * sf) / ra, 1.0 / sn);
    alat = 2.0 * Math.atan(alat) - Math.PI * 0.5;

    let theta;

    if (Math.abs(xn) <= 0.0) {
      theta = 0.0;
    } else {
      if (Math.abs(yn) <= 0.0) {
        theta = Math.PI * 0.5;
        if (xn < 0.0) theta = -theta;
      } else theta = Math.atan2(xn, yn);
    }
    let alon = theta / sn + olon;
    rs["lat"] = alat * RADDEG;
    rs["lng"] = alon * RADDEG;
  }
  return rs;
}

// clean code

// const n = (a, b) => {
//   return a.filter((ae) => b.findIndex((be) => be.id === ae.id) === -1);
// };

// clean code

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

// 합집합
function union(a, b) {
  return [...a, ...not(b, a)];
}

// 차집합 a,b에서 a에서 b가 포함된 부분을 뺀 값
function difference2(a, b) {
  return a.filter((f) => !b.includes(f));
}

// 교집합 a, b에서 a와 b두 집합에 포함된 값
function intersection2(a, b) {
  return a.filter((f) => b.includes(f));
}

// 베타적 논리합 a,b에 포함되지 않은 a와 b집합의 합
function exclusiveOR(a, b) {
  return difference2(a, b).concat(difference2(b, a));
}

// 입력 날짜기준의 월요일 구하기
function getMondayFromDate(d) {
  let paramDate = new Date(d);
  paramDate.setDate(paramDate.getDate() - paramDate.getDay() + 1);
  return paramDate;
}

/**
 * 숫자 배열에서 최고값 리턴하는 함수
 * @param {values} 숫자형태의 배열
 * @returns 최고값
 */
const max = (values) => {
  return values.reduce((acc, curr) => {
    return curr > acc ? curr : acc;
  }, values[0]);
};

/**
 * 숫자 배열을 합산하여 리턴하는 함수
 * @param {values} 숫자형태의 배열
 * @returns 합산 값
 */
const sum = (values) => {
  return values.reduce((acc, curr) => {
    return acc + curr;
  }, 0); // 초기값
};

/**
 * 숫자 배열을 합산하여 평균을 리턴하는 함수
 * @param {values} 숫자 형태의 배열
 * @returns 평균 값
 */
const average = (values) => {
  return sum(values) / values.length;
};

/**
 * 특정 숫자를 offset으로 나누기 하였을 때 소수점 남지않도록 조정하여 리턴하는 함수
 * @param {value} 특정 숫자
 * @param {offset} 나누기 할 숫자
 * @returns 조정된 값
 */
const offsetCarry = (value, offset) => {
  return value + (value % offset);
};

const formatPhoneWithDashbar = (phoneNumber) => {
  return phoneNumber.replace(regex.CNV_PHONENUMBER, "$1-$2-$3");
};

/*
일수만큼 빼거나 더한 날짜를 리턴해주는 함수
-1 또는 1
@param {day} : 일수
@returns 일수를 밴 날짜
*/
const setDate = (day) => {
  return new Date(
    new Date().setDate(new Date().getDate() + day)
  ).toDateString();
};

export {
  consts,
  cloudAxios,
  axios,
  paramsSerializer,
  regex,
  isEmpty,
  sleep,
  descendingComparator,
  getComparator,
  stableSort,
  setterPartition,
  durationMinute,
  numberFixedFormat,
  getColor,
  transparentize,
  getSundayFromDate,
  getSaturdayFromDate,
  getFirstdayFromDate,
  getLastdayFromDate,
  getExtensionOfFilename,
  extractFileExt,
  shortenWords,
  getFirstLetter,
  fileDownloadFromCloud,
  getResourceUrlfromCloud,
  dfs_xy_conv,
  not,
  intersection,
  union,
  difference2,
  intersection2,
  exclusiveOR,
  getMondayFromDate,
  max,
  sum,
  average,
  offsetCarry,
  formatPhoneWithDashbar,
  setDate,
};

export const getDefaultState = () => ({
  authentication: {
    login: {
      id: "",
      password: "",
      optional: true,
      saveId: false,
      autoLogin: false,
    },

    forgetpassword: {
      newPassword: "",
      newPasswordConfirm: "",
    },
  },
});

// eslint-disable-next-line no-extend-native
// Array.prototype.division = function (n) {
//   var arr = this;
//   var len = arr.length;
//   var cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
//   var tmp = [];

//   for (var i = 0; i < cnt; i++) {
//     tmp.push(arr.splice(0, n));
//   }
//   return tmp;
// };
